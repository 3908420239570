import client1 from '../Assets/ClientsAssets/client1.png'
import client2 from '../Assets/ClientsAssets/client2.png'
import client3 from '../Assets/ClientsAssets/client3.png'
import client4 from '../Assets/ClientsAssets/client4.png'
import client5 from '../Assets/ClientsAssets/client5.png'
import client6 from '../Assets/ClientsAssets/client6.png'
import client7 from '../Assets/ClientsAssets/client7.png'
import client8 from '../Assets/ClientsAssets/client8.png'
import client9 from '../Assets/ClientsAssets/client9.png'
import client10 from '../Assets/ClientsAssets/client10.png'

const clientData = [
    {
        id: 1,
        imageC : <img src={client1} alt="alkj" />
    },
    {
        id: 2,
        imageC : <img src={client2} alt="alkj" />
    },
    {
        id: 3,
        imageC : <img src={client3} alt="alkj" />
    },
    {
        id: 4,
        imageC : <img src={client4} alt="alkj" />
    },
    {
        id: 5,
        imageC : <img src={client5} alt="alkj" />
    },
    {
        id: 6,
        imageC : <img src={client6} alt="alkj" />
    },
    {
        id: 7,
        imageC : <img src={client7} alt="alkj" />
    },
    {
        id: 8,
        imageC : <img src={client8} alt="alkj" />
    },
    {
        id: 9,
        imageC : <img src={client9} alt="alkj" />
    },
    {
        id: 10,
        imageC : <img src={client10} alt="alkj" />
    },
]

export default clientData